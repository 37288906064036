import React from 'react';
import axios from 'axios'

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_method: null,
      full_name: '',
      pay_info: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const url = `/conventions/holiday-matsuri-${this.props.convention.year}/events/${this.props.event.uuid}/payment_info`
    const axiosInstance = axios.create({
      headers: {
        'X-CSRF-Token': this.props.authToken,
        'Content-Type': 'application/json'
      }
    });
    axiosInstance.post(url, this.state).then(response => {
      this.props.onSuccess(response.data, this.state.payment_method)
      console.log('Submitted values:', this.state);
    })
    
  }

  renderPayInfo(){
    if(this.state.payment_method == 0){
      return(
        <div>
          <div>
            <label className='form-label'>
              Full Name (as it appears in PayPal):
              <input className='form-control' type="text" name="full_name" value={this.state.full_name} onChange={this.handleChange} />
            </label>
          </div>
          <div>
            <label className='form-label'>
              PayPal.Me Link:
              <input className='form-control' type="text" name="pay_info" value={this.state.pay_info} onChange={this.handleChange} />
            </label>
          </div>
          <div>
            <button type="submit" className='btn btn-secondary'>Submit</button>
          </div>
        </div>
      )
    }else if(this.state.payment_method == 1 ){
      return(
        <div>
          <div>
            <label className='form-label'>
              Full Name (as it appears in Venmo):
              <input className='form-control' type="text" name="full_name" value={this.state.full_name} onChange={this.handleChange} />
            </label>
          </div>
          <div>
            <label className='form-label'>
              Venmo Username:
              <input className='form-control' type="text" name="pay_info" value={this.state.pay_info} onChange={this.handleChange} />
            </label>
          </div>
          <div className='p-2'>
            <button type="submit" className='btn btn-secondary'>Submit</button>
          </div>
        </div>
      )
    }else if(this.state.payment_method == null){
      return(
        <div>
          <h3>Please Select a Payment Method!</h3>
        </div>
      )
    }
  }

  render() {
    return (
      <div className='card py-5'>
        <h2>In order to compensate you for hosting your panel(s) please fill out the form below.</h2>
        <form onSubmit={this.handleSubmit}>
          <div>
            <label className='form-label'>
              Payment Method:
              <select className='form-control' name="payment_method" value={this.state.payment_method} onChange={this.handleChange}>
                <option value={null}>Select a method</option>
                <option value={0}>PayPal</option>
                <option value={1}>Venmo</option>
              </select>
            </label>
          </div>
          {this.renderPayInfo()}
        </form>
      </div>
    );
  }
}

export default PaymentForm;