import React, { useState, useEffect } from 'react';
import './CountDown.css'; // Assuming you're adding the styles here

const CountDown = ({ targetDateTime }) => {
  const calculateTimeRemaining = (targetTime) => {
    const currentTime = new Date();
    const timeRemaining = targetTime - currentTime;
    return timeRemaining > 0 ? timeRemaining : 0;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(new Date(targetDateTime.app_open_date)));
  const [isOpen, setIsOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const startTime = new Date(targetDateTime.app_open_date);
      const endTime = new Date(targetDateTime.panel_hard_cap_date);
      const remainingTime = calculateTimeRemaining(startTime);

      setTimeRemaining(remainingTime);

      if (currentTime >= startTime && currentTime <= endTime) {
        setIsOpen(true);
        setIsClosed(false);
      } else if (currentTime > endTime) {
        setIsOpen(false);
        setIsClosed(true);
      } else {
        setIsOpen(false);
        setIsClosed(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDateTime]);

  const formatTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="countdown-container">
      <div className="snowflakes" aria-hidden="true">
        <div className="snowflake">❅</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❅</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❆</div>
        <div className="snowflake">❅</div>
        <div className="snowflake">❆</div>
      </div>
      {isClosed ? (
        <p className="countdown-text closed">Panel Applications are currently <span className="countdown-time closed-time">CLOSED</span></p>
      ) : isOpen ? (
        <div>
          <p className="countdown-text">Panel Applications are <span className="countdown-time">OPEN</span></p>
          <a href={`/conventions/holiday-matsuri-${targetDateTime.year}/event_applications/new`} className="btn btn-primary">Click here to Apply!</a>
        </div>
      ) : (
        <p className="countdown-text">
          Panel applications go live in: <span className="countdown-time">{formatTime(timeRemaining)}</span>
        </p>
      )}
    </div>
  );
};

export default CountDown;
