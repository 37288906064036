// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("jquery")
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels";
import 'bootstrap/dist/js/bootstrap'
import "bootstrap/dist/css/bootstrap";
Rails.start()
Turbolinks.start()
ActiveStorage.start()
import ReactOnRails from 'react-on-rails';
import CountDown from "../components/static/countdown";
import AcceptEvent from "../components/events/base";

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  CountDown,
  AcceptEvent

});




