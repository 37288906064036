import React from 'react'
import moment from 'moment'
import PaymentForm from './form'

export default class AcceptEvent extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      payment_method: false,
      pay_info: '',
      showForm: false
    }
  }
  componentDidMount(){
    if(this.props.payment_method === true){
      this.setState({payment_method: true, pay_info: this.props.pay_info.pay_method})
    }
  }
  handlePaymentSuccess = (data, info) => {
    console.log('Capture of Payment Info was successful!', data);
    this.setState({pay_info: info, payment_method: true})
  };

  renderDay(event){
    const startHour = moment(event.start_datetime).hour()
    if(startHour < 3){
      return moment(event.start_datetime).subtract(1, 'days').format('dddd')
    }else{
      return moment(event.start_datetime).format('dddd')
    }
  }

  renderMethod(){
    if(this.state.pay_info == 0){
      return "PayPal"
    }else if(this.state.pay_info == 1){
      return "Venmo"
    }else{
      return this.state.pay_info
    }
  }

  renderForm(){
    if(this.state.payment_method === false){
      return(
        <PaymentForm authToken={this.props.authToken} event={this.props.event} convention={this.props.convention} onSuccess={this.handlePaymentSuccess} />
      )
    }else{
      return(
        <h4>Payment Info: {this.renderMethod()}</h4>
      )
    }
  }

  renderButtons(){
    if(this.state.payment_method === true){
      return(
        <div className='py-2'>
          <a href={`/conventions/${this.props.convention.slug}/events/${this.props.event.uuid}/accept?pass=${this.props.pass}`} className='btn btn-lg btn-success'>Accept</a>
          <a href={`/conventions/${this.props.convention.slug}/events/${this.props.event.uuid}/decline?pass=${this.props.pass}`} className='btn btn-lg btn-danger'>Decline</a>
        </div>
      )
    }else{
      return(
        <div className='py-2'>
          <button className='btn btn-lg btn-success' disabled>Accept</button>
          <a href={`/conventions/${this.props.convention.slug}/events/${this.props.event.uuid}/decline?pass=${this.props.pass}`} className='btn btn-lg btn-danger'>Decline</a>
        </div>
      )
    }
  }
  render(){
    return(
      <div className='col align-self-center'>
        <h1>Panel timeslot for: {this.props.event.event_name}</h1>
        <ul className="list-unstyled">
          <li><strong>Name:</strong >{this.props.event.event_name}</li>
          <li><strong>Description:</strong >{this.props.event.external_desc}</li>
          <li><strong>Day:</strong >{this.renderDay(this.props.event)}</li>
          <li><strong>Time:</strong >{moment(this.props.event.start_datetime).format('ddd')} @ {moment(this.props.event.start_datetime).format('hh:mm A')} to {moment(this.props.event.end_datetime).format('ddd')} @ {moment(this.props.event.end_datetime).format('hh:mm A')}</li>
          <li><strong>Location:</strong> {this.props.event.location}</li>
        </ul>
        {this.renderForm()}
        {this.renderButtons()}
      </div>
    )
  }
}